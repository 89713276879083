import { Box, Button, Typography } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { colorPrimitives } from 'components/Theme'
import { DataTable } from 'components/Shared/DataTable'
import { type GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid'
import FileIcon from 'assets/file.svg'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'

export const ReportExportModal = ({
  isModalOpen,
  toggleModal,
  reportName,
  exportColumns,
  records,
  setMessage,
}: {
  isModalOpen: boolean
  toggleModal: () => void
  reportName: string
  exportColumns: GridColDef[]
  records: any[]
  setMessage: (message: string | null) => void
}) => {
  const dataTableApiRef = useGridApiRef()

  const exportReportData = () => {
    dataTableApiRef.current.exportDataAsCsv({
      fileName: `${reportName}`,
      utf8WithBom: true,
    })
    toggleModal()
    setMessage('Records have been downloaded.')
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModal}
      sx={{
        height: { xs: '100%', sm: '50%' },
        width: { xs: '100%', sm: '40%' },
        overflow: 'hidden',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleModal}
        title={`Export ${reportName}`}
      />
      <>
        <Box paddingX={{ xs: 2, sm: 4 }} paddingY={{ xs: 3, sm: 1 }}>
          <Box
            className={`p-6 border-[1px] border-[${colorPrimitives.borderGray}] rounded-lg mt-1 mb-3`}
          >
            <Box className="flex flex-col lg:flex-row gap-6 lg:w-3/4 lg:items-center">
              <Box className="min-w-[200px]">
                <Typography variant="h5">Records to export</Typography>
                <Box className="flex flex-row items-stretch gap-1">
                  <img
                    src={FileIcon}
                    width={20}
                    alt="Export icon"
                    className="mb-1"
                  />
                  <Typography variant="body-1">
                    {`${records.length} records`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography variant="body-2" color="text.secondary">
            CSV file format will be exported
          </Typography>
        </Box>
        <Box
          className="fixed bottom-0 border-t-[1px] w-full flex justify-end gap-2
   px-8 py-6 sm:p-4"
        >
          <Button variant="text" onClick={toggleModal}>
            Cancel
          </Button>
          <Button variant="contained" type="button" onClick={exportReportData}>
            Export
          </Button>
        </Box>
        <Box display="none">
          <DataTable
            apiRef={dataTableApiRef}
            columns={exportColumns}
            rows={records ?? []}
            slots={{ toolbar: GridToolbar }}
            forceDesktopTableInMobile
          />
        </Box>
      </>
    </Modal>
  )
}
