import { createTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  ThemeProvider as MuiThemeProvider,
  type PaletteOptions,
} from '@mui/material/styles'
import type React from 'react'
import { type ReactNode } from 'react'
import type {} from '@mui/x-data-grid/themeAugmentation'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    display: React.CSSProperties
    'subtitle-1': React.CSSProperties
    'subtitle-2': React.CSSProperties
    'label-form': React.CSSProperties
    'label-CTA': React.CSSProperties
    'body-1': React.CSSProperties
    'body-2': React.CSSProperties
    'body-3': React.CSSProperties
    caption: React.CSSProperties
    numbers: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    display?: React.CSSProperties
    'subtitle-1': React.CSSProperties
    'subtitle-2': React.CSSProperties
    'label-form': React.CSSProperties
    'label-CTA': React.CSSProperties
    'body-1': React.CSSProperties
    'body-2': React.CSSProperties
    'body-3': React.CSSProperties
    caption: React.CSSProperties
    numbers: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true
    'subtitle-1': true
    'subtitle-2': true
    'label-form': true
    'label-CTA': true
    'body-1': true
    'body-2': true
    'body-3': true
    caption: true
    numbers: true
  }
}

export const colors = {
  primary: {
    main: '#CC2027',
  },
  secondary: {
    main: '#1A4F64',
    light: '#F7F7F7',
  },
  background: {
    paper: '#ffffff',
  },
  text: {
    primary: '#2C2C27',
    secondary: '#292B2699',
    disabled: '#292B2659',
  },
  error: {
    main: '#CC2027',
  },
  warning: {
    main: '#FF5F15',
  },
  success: {
    main: '#2E7D32',
  },
} satisfies PaletteOptions

const baseTheme = createTheme()
const mobileBreakpoint = baseTheme.breakpoints.values.md

const theme = createTheme({
  palette: {
    mode: 'light',
    ...colors,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: colors.text.primary,
          borderBottomStyle: 'solid',
          borderBottomColor: grey[300],
          boxShadow: 'none',
          borderBottomWidth: 1,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 'bold',
          paddingRight: '16px',
          paddingLeft: '16px',
          lineHeight: 'normal',
          gap: '4px',
          '&:hover': {
            boxShadow: 'none',
          },
          height: '48px',
          width: 'auto',
          minWidth: 126,
          '@media (max-width: 600px)': {
            width: '100%',
          },
        },
        outlined: {
          borderColor: grey[400],
          '&:hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiInputBase: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: colors.text.primary,
          marginBottom: '8px',
        },
        asterisk: {
          visibility: 'hidden',
          '&.MuiInputLabel-asterisk::after': {
            content: '"(Required)"',
            visibility: 'visible',
            color: colors.primary.main,
            fontWeight: '400',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.primary.main,
            borderRadius: '4px',
          },
          '&.Mui-selected .MuiSvgIcon-root': {
            color: colors.primary.main,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '24px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          paddingLeft: '8px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderColor: 'rgba(41, 43, 38, 0.08)',
          borderRadius: '4px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid #2C2C270A',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          padding: 8,
          fontSize: '16px',
          cursor: 'pointer',
          borderColor: 'rgba(41, 43, 38, 0.08)',
          borderBottom: 'none',
        },
        head: {
          backgroundColor: '#ffffff',
          fontWeight: 700,
          fontSize: '16px',
          border: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-child(2n)': {
            backgroundColor: '#fcfcfd',
          },
          '&.MuiTableRow-hover:hover': {
            backgroundColor: 'rgba(41, 43, 38, 0.08)',
          },
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          maxWidth: '100vw',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: '400',
          fontSize: '16px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderColor: 'rgba(228, 227, 230, 1)',
          color: 'rgba(41, 43, 38, 0.6)',
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important',
            fontSize: '16px',
          },
          '& .MuiDataGrid-cell:first-of-type': {
            color: '#2C2C27',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(228, 227, 230, 1)',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        },
        columnHeader: {
          backgroundColor: 'rgba(44, 44, 39, 0.04)',
          color: '#2C2C27',
          padding: '14px',
        },
        columnHeaderTitle: {
          lineHeight: '28px',
          fontSize: '16px',
          fontWeight: '700',
          color: '#2C2C27',
        },
        cell: {
          borderColor: 'rgba(228, 227, 230, 1)',
          padding: '14px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          minWidth: 220,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          maxWidth: '500px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          display: 'h1',
          'body-1': 'p',
          'body-2': 'p',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Soleil',
    display: {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '48px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '32px',
      },
    },
    h1: {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '40px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '28px',
      },
    },
    h2: {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '32px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '24px',
      },
    },
    h3: {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '24px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '18px',
      },
    },
    h4: {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '20px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '16px',
      },
    },
    'subtitle-1': {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '18px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '18px',
      },
    },
    'subtitle-2': {
      fontWeight: 700,
      margin: 0,
      padding: 0,
      [baseTheme.breakpoints.up(mobileBreakpoint)]: {
        fontSize: '16px',
      },
      [baseTheme.breakpoints.down(mobileBreakpoint)]: {
        fontSize: '16px',
      },
    },
    'label-form': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 400,
    },
    'label-CTA': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 700,
      letterSpacing: 1,
    },
    'body-1': {
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 400,
    },
    'body-2': {
      fontSize: '14px',
      margin: 0,
      padding: 0,
      fontWeight: 400,
    },
    'body-3': {
      fontSize: '14px',
      lineHeight: '21px',
      margin: 0,
      padding: 0,
      fontWeight: 400,
      color: 'rgba(41, 43, 38, 0.6)',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '20px',
      margin: 0,
      padding: 0,
      fontWeight: 400,
      color: '#2C2C27',
    },
    numbers: {
      fontFamily: 'Courier',
      fontSize: '16px',
      margin: 0,
      padding: 0,
      fontWeight: 400,
    },
  },
})

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
