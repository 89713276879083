import { isNil } from 'lodash'
import { formatDateTimeInUTC } from 'utils/util'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishmentLicense } from 'src/types/api'
import { LicensesContact } from './LicensesContact'
import { colorPrimitives } from 'components/Theme'
import { formatInTimeZone } from 'date-fns-tz'

export const LicenseCardLicensedEstablishmentPage = ({
  license,
}: {
  license?: LicensedEstablishmentLicense
}) => {
  const theme = useTheme()
  const statusColorMap: Record<string, string> = {
    UpToDate: theme.palette.success.main,
    ExpiringSoon: theme.palette.warning.main,
  }
  const gamingLicense = license?.licensedEstablishmentLicenseType === 'Gaming'

  const formatDateTimeInUTCgamingLicense = (
    stringDate: string | null | undefined
  ) => {
    if (isNil(stringDate)) return

    const date = new Date(stringDate)
    const format = date.getMonth() === 4 ? 'MMM d' : 'MMM. d'

    return formatInTimeZone(date, 'Etc/UTC', format)
  }

  const colorStatusLicense = (license: LicensedEstablishmentLicense | null) => {
    if (isNil(license)) return theme.palette.error.main

    const licenseStatus = license.licenseStatus

    if (gamingLicense) {
      return colorPrimitives.lightCyanBlue
    } else {
      return licenseStatus
        ? statusColorMap[licenseStatus] || theme.palette.error.main
        : theme.palette.error.main
    }
  }

  const statusTypeLicenseDictionary: Record<string, string> = {
    Gaming: 'Gaming',
    StateLiquor: 'State Liquor',
    LocalLiquor: 'Local Liquor',
  }

  const statusDateExpLicenseDictionary: Record<string, string> = {
    UpToDate: 'Up to date',
    ExpiringSoon: 'Expiring Soon',
    Expired: 'Expired',
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Box
        sx={{
          borderLeft: `3px solid ${
            license && gamingLicense
              ? colorPrimitives.lightCyanBlue
              : license && colorStatusLicense(license)
          }`,
          backgroundColor: theme.palette.background.paper,
          py: 1,
          px: 2,
        }}
        className="shadow"
      >
        <Typography
          variant="body-2"
          sx={{
            color: isNil(license)
              ? theme.palette.text.primary
              : colorStatusLicense(license),
            paddingBottom: 1,
            fontWeight: 'bolder',
          }}
          className="uppercase"
        >
          {isNil(license) && 'No Licenses Found'}
          {gamingLicense
            ? 'Reminder'
            : statusDateExpLicenseDictionary[String(license?.licenseStatus)]}
        </Typography>

        <Typography variant={isNil(license) ? 'body-3' : 'subtitle-2'}>
          {isNil(license) ? (
            <LicensesContact />
          ) : (
            statusTypeLicenseDictionary[
              String(license.licensedEstablishmentLicenseType)
            ]
          )}
        </Typography>

        {!isNil(license) && (
          <Typography
            variant="body-2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {gamingLicense ? 'Renew Annually before: ' : 'Expiration Date: '}
            <Typography
              variant="body-3"
              sx={{ color: colorStatusLicense(license), mx: '1%' }}
            >
              {gamingLicense
                ? formatDateTimeInUTCgamingLicense(license.expirationDate)
                : formatDateTimeInUTC(license.expirationDate)}
            </Typography>
          </Typography>
        )}
      </Box>
    </Grid>
  )
}
