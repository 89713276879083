import { Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { getReportStartAndEndDates } from 'utils/util'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { RewardRedemptionsTable } from './RewardRedemptionsTable'
import { useGetRewardRedemptionsByLeId } from 'hooks/api/Reports/useGetRewardRedemptionsByLeId'
import { useParams } from 'react-router-dom'
import { ReportsHeader } from '../ReportsHeader'

export const RewardRedemptionsPage = () => {
  const { id } = useParams()
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const { startDate, endDate } = getReportStartAndEndDates(4, 0)
  const piiPermission = meQuery.data?.user?.reportsAccessToPIIPermission
  const downloadPIIPermission = meQuery.data?.user?.reportsDownloadPIIPermission

  const reportQuery = useGetRewardRedemptionsByLeId({
    currentPage: 1,
    pageSize: 100,
    licensedEstablishmentId: Number(id),
    startDate,
    endDate,
  })

  return (
    <ReportsHeader currentTab={`/Reports/${id}/PatronRewardRedemptions`}>
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data &&
            (reportQuery.data.results?.length === 0 ? (
              <Typography variant="h4">
                No data available for the selected location
              </Typography>
            ) : (
              <RewardRedemptionsTable
                data={reportQuery.data.results ?? []}
                piiPermission={piiPermission ?? false}
                downloadPIIPermission={downloadPIIPermission ?? false}
              />
            ))}
        </Stack>
      )}
    </ReportsHeader>
  )
}
