import { LoginPage } from 'pages/Login/LoginPage'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom'
import { allPaths, type Path } from './paths'
import { PatronAccountsPage } from 'pages/PatronAccounts/PatronAccountsPage'
import { HomePage } from 'pages/Home/HomePage'
import { LocationsPage } from 'pages/Locations/LocationsPage'
import { ReportsPage } from 'pages/Reports/ReportsPage'
import { WeeklySnapshotTablePage } from 'pages/Reports/WeeklySnapshot/WeeklySnapshotTablePage'
import { RewardsPage } from 'pages/Rewards/RewardsPage'
import { PromotionsPage } from 'pages/Promotions/PromotionsPage'
import { PromotionDetailsPage } from 'pages/Promotions/PromotionDetailsPage/PromotionsDetailsPage'
import { SweepstakesPage } from 'pages/Sweepstakes/SweepstakesPage'
import { LicensedEstablishmentUsersPage } from 'pages/LicensedEstablishmentUsers/LicensedEstablishmentUsersPage'
import { LicensedEstablishmentDetailsPage } from 'pages/LicensedEstablishmentDetailsPage/LicensedEstablishmentDetailsPage'
import { pathPermissions } from './pathPermissions'
import { PatronActivityPage } from 'pages/Reports/PatronActivityStats/PatronActivityPage'
import { CreditsWageredByHourPage } from 'pages/Reports/CreditsWageredByHour/CreditsWageredByHourPage'
import * as _ from 'lodash'
import { ProtectedLayout } from './ProtectedLayout'
import { RewardProgramDetailPage } from 'pages/Rewards/RewardProgramDetailPage'
import { RewardProgramRedemptionPage } from 'pages/Rewards/RewardProgramRedemptionPage'
import { CreditsWageredByMachinePage } from 'pages/Reports/CreditsWageredByMachine/CreditsWageredByMachinePage'
import { VgtSnapshotPage } from 'pages/Reports/VgtSnapshot/VgtSnapshotPage'
import { VgtTrendsPage } from 'pages/Reports/VgtTrends/VgtTrendsPage'
import { CreditsWageredHourlyTrendsPage } from 'pages/Reports/CreditsWageredHourlyTrends/CreditsWageredHourlyTrendsPage'
import { TrailingNtiTrendsPage } from 'pages/Reports/TrailingNtiTrends/TrailingNtiTrendsPage'
import { CreateAccountPage } from 'pages/Login/CreateAccount/CreateAccountPage'
import { WeeklySnapshotLocationSelectPage } from 'pages/Reports/WeeklySnapshot/WeeklySnapshotLocationSelectPage'
import { DevelopHome } from 'pages/Development/DevelopHome'
import { RewardProgramActivityLogPage } from 'pages/Rewards/RewardProgramActivityLogPage'
import { SweepstakeOverviewPage } from 'pages/Sweepstakes/SweepstakesDetailsPage/SweepstakeOverviewPage'
import { SweepstakeParticipatingLocationsPage } from 'pages/Sweepstakes/SweepstakesDetailsPage/ParticipatingLocations/SweepstakeParticipatingLocationsPage'
import { SweepstakesAdditionalEnrollmentDetailsPage } from 'pages/Sweepstakes/SweepstakesAdditionalEnrollmentDetailsPage/SweepstakesAdditionalEnrollmentDetailsPage'
import { ManageEnrollLocationsPage } from 'pages/Sweepstakes/SweepstakesEnrollLocationsPage/ManageEnrollLocationsPage'
import { SweepstakesReviewEnrollmentPage } from 'pages/Sweepstakes/SweepstakesReviewEnrollment/SweepstakesReviewEnrollmentPage'
import { RewardCatalogDetailPage } from 'pages/Rewards/RewardCatalogDetailPage'
import { SweepstakesWinnersPage } from 'pages/Sweepstakes/SweepstakesDetailsPage/SweepstakesWinnersPage/SweepstakesWinnersPage'
import { FlexPayPage } from 'pages/Reports/FlexPay/FlexPayPage'
import { PatronCheckInsPage } from 'pages/Reports/CheckIns/PatronCheckInsPage'
import { PatronListPage } from 'pages/Reports/PatronList/PatronListPage'
import { RewardRedemptionsPage } from 'pages/Reports/RewardRedemptions/RewardRedemptionsPage'
import { NewLEConnectUserPage } from 'pages/LicensedEstablishmentUsers/NewLEConnectUserPage'
import { ReportsOverview } from 'pages/Reports/ReportsOverview'

const pathsToPages: Record<Path, JSX.Element> = {
  '/': <LoginPage />,
  '/CreateAccount': <CreateAccountPage />,
  '/Home': <HomePage />,
  '/Development/Home': <DevelopHome />,
  '/PatronAccounts': <PatronAccountsPage />,
  '/Locations': <LocationsPage />,
  '/Reports': <ReportsPage />,
  '/Reports/:id/PatronCheckins': <PatronCheckInsPage />,
  '/Reports/:id/PatronList': <PatronListPage />,
  '/Reports/:id/PatronRewardRedemptions': <RewardRedemptionsPage />,
  '/Reports/ReportsOverview': <ReportsOverview />,
  '/Reports/:id/FlexPay': <FlexPayPage />,
  '/Reports/:id/PatronActivity': <PatronActivityPage />,
  '/Reports/WeeklySnapshotLocationSelect': <WeeklySnapshotLocationSelectPage />,
  '/Reports/WeeklySnapshot': <WeeklySnapshotTablePage />,
  '/Reports/:id/CreditsWageredByMachine': <CreditsWageredByMachinePage />,
  '/Reports/:id/CreditsWageredByHour': <CreditsWageredByHourPage />,
  '/Reports/:id/VgtSnapshot': <VgtSnapshotPage />,
  '/Reports/:id/VgtTrends': <VgtTrendsPage />,
  '/Reports/:id/CreditsWageredHourlyTrends': <CreditsWageredHourlyTrendsPage />,
  '/Reports/:id/TrailingNtiTrends': <TrailingNtiTrendsPage />,
  '/Rewards': <RewardsPage />,
  '/Promotions': <PromotionsPage />,
  '/Promotions/:id': <PromotionDetailsPage />,
  '/Sweepstakes': <SweepstakesPage />,
  '/Sweepstakes/:id/Overview': <SweepstakeOverviewPage />,
  '/Sweepstakes/:id/Winners': <SweepstakesWinnersPage />,
  '/Sweepstakes/:id/ParticipatingLocations': (
    <SweepstakeParticipatingLocationsPage />
  ),
  '/Sweepstakes/:id/EnrollLocations': <ManageEnrollLocationsPage />,
  '/Sweepstakes/:id/AdditionalEnrollmentDetails': (
    <SweepstakesAdditionalEnrollmentDetailsPage />
  ),
  '/Sweepstakes/:id/ReviewLocationsToEnroll': (
    <SweepstakesReviewEnrollmentPage />
  ),
  '/LicensedEstablishmentUsers': <LicensedEstablishmentUsersPage />,
  '/LicensedEstablishmentUsers/NewLEConnectUserPage': <NewLEConnectUserPage />,
  '/LicensedEstablishments/:id': <LicensedEstablishmentDetailsPage />,
  // '/LiveAtLocation': <LiveAtLocationPage />,
  // '/LiveAtLocation/PatronDetails/:id/LicensedEstablishment/:licensedEstablishmentId':
  //   <PatronDetailsPage />,
  // '/LiveAtLocation/PatronDetails/:id': <PatronDetailsPage />,
  '/RewardsProgram/:id': <RewardProgramDetailPage />,
  // '/RewardsProgram/:id/Settings': <RewardProgramSettingsPage />,
  '/RewardsProgram/:id/Redemption': <RewardProgramRedemptionPage />,
  '/RewardsProgram/:id/ActivityLog': <RewardProgramActivityLogPage />,
  '/RewardsProgram/:id/RewardsCatalog/:rewardCatalogId': (
    <RewardCatalogDetailPage />
  ),
}

const [publicPaths, protectedPaths] = _.partition(
  allPaths,
  (path) => pathPermissions[path] === 'always'
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {publicPaths.map((path) => (
        <Route key={path} path={path} element={pathsToPages[path]} />
      ))}

      <Route element={<ProtectedLayout />}>
        {protectedPaths.map((path) => (
          <Route key={path} path={path} element={pathsToPages[path]} />
        ))}
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </>
  )
)

export const Navigation = () => {
  return <RouterProvider router={router} />
}
