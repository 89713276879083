import { Box, CircularProgress } from '@mui/material'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { LicenseDetailLicensedEstablishmentPage } from './LicenseDetailLicensedEstablishmentPage'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const LicensesListLicensedEstablishmentPage = () => {
  const selectedCorporateAccount = useCurrentCorporateAccountStore()
  const licensedEstablishmentsQuery =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId: Number(
        selectedCorporateAccount.currentCorporateAccountId
      ),
    })

  if (licensedEstablishmentsQuery.isPending) {
    return <CircularProgress />
  }

  if (licensedEstablishmentsQuery.isError) {
    return <p>An error occurred.</p>
  }

  const licensedEstablishments = licensedEstablishmentsQuery.data

  return (
    <Box sx={{ py: 1 }}>
      {licensedEstablishments.map((licensedEstablishment, index) => {
        return (
          <LicenseDetailLicensedEstablishmentPage
            key={licensedEstablishment.id}
            licensedEstablishmentLicenseNumber={
              licensedEstablishment.licenseNumber
            }
            licensedEstablishmentName={
              licensedEstablishment.standardName ?? licensedEstablishment.name
            }
            licensedEstablishmentLicenses={
              licensedEstablishment.licensedEstablishmentLicenses ?? []
            }
            uniqueLicensedEstablishment={
              index === 0 && licensedEstablishments.length === 1
            }
          />
        )
      })}
    </Box>
  )
}
