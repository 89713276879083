import { isPresent } from '@jjvgaming/player-payback-library'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'
import {
  type FullLicensedEstablishmentUserDTO,
  type UpdateLicensedEstablishmentUserRequest,
} from 'src/types/api'

interface UsePutLicensedEstablishmentUserParams {
  licensedEstablishmentUserId: number
  corporateAccountId: number
  onSuccess?: (data: FullLicensedEstablishmentUserDTO) => Promise<void>
  onError?: (error: ApiError) => Promise<void>
}

export const usePutJJConnectUser = ({
  licensedEstablishmentUserId,
  corporateAccountId,
  onSuccess,
  onError,
}: UsePutLicensedEstablishmentUserParams) => {
  const queryClient = useQueryClient()
  const put = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/{id}',
    method: 'put',
  })

  return useMutation({
    mutationFn: async (
      licensedEstablishmentUser: UpdateLicensedEstablishmentUserRequest
    ) => {
      const { data } = await put({
        ...licensedEstablishmentUser,
        id: licensedEstablishmentUserId,
        corporateAccountId,
      })
      return { data }
    },
    onSuccess: async ({ data }) => {
      await queryClient.invalidateQueries({
        queryKey: [
          '/le-connect/licensed-establishments/users/{id}',
          licensedEstablishmentUserId,
        ],
      })
      if (isPresent(onSuccess)) {
        await onSuccess(data)
      }
    },
    onError,
  })
}
