import { useApiQuery } from 'hooks/useApiQuery'
import { format } from 'date-fns'

const path = '/le-connect/patron/checkins/range/{currentPage}/{pageSize}'
const method = 'get'

interface UseGetPatronCheckinsByLeAndRangeParams {
  currentPage: number
  pageSize: number
  licensedEstablishmentId: number | undefined
  startDate: Date
  endDate: Date
  distinct?: boolean
}

const formatDate = (d: Date) => format(d, 'yyyy-MM-dd HH:mm:ssXXX')

export const useGetPatronCheckinsByLeAndRange = ({
  currentPage,
  pageSize,
  licensedEstablishmentId,
  startDate,
  endDate,
}: UseGetPatronCheckinsByLeAndRangeParams) =>
  useApiQuery({
    path,
    method,
    queryKey: [
      path,
      licensedEstablishmentId,
      startDate.getTime(),
      endDate.getTime(),
    ],
    queryArgs: {
      currentPage,
      pageSize,
      licensedEstablishmentId,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
  })
