import { ReportsHeader } from './ReportsHeader'
import { ReportsOverview } from './ReportsOverview'

export const ReportsPage = () => {
  return (
    <ReportsHeader currentTab={'/Reports/ReportsOverview'}>
      <ReportsOverview />
    </ReportsHeader>
  )
}
