import { Button, Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { endOfDay, format, startOfDay, subDays } from 'date-fns'
import { useGetVgtSnapshotReport } from 'hooks/api/Reports/useGetVgtSnapshotReport'
import { VgtSnapshotChart } from './VgtSnapshotChart'
import { useParams } from 'react-router-dom'
import { ReportsHeader } from '../ReportsHeader'

export const VgtSnapshotPage = () => {
  const { id } = useParams()
  const endDate = endOfDay(subDays(new Date(), 1))
  const startDate = startOfDay(subDays(endDate, 6))

  const reportQuery = useGetVgtSnapshotReport({
    licensedEstablishmentId: Number(id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  const [displayType, setDisplayType] = useState<'$' | '%'>('$')

  const buttonStyle = (type: '$' | '%') => ({
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '1.8rem',
    backgroundColor: displayType === type ? '#CC2027' : '#e0e0e0',
    color: displayType === type ? 'white' : 'black',
  })

  return (
    <ReportsHeader currentTab={`/Reports/${id}/VgtSnapshot`}>
      <Typography variant="h4">
        {`Last 7 Days: ${formattedStartDate} - ${formattedEndDate}`}
      </Typography>
      <Stack spacing={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sx={{ mt: 5 }}>
            <Button
              variant="contained"
              onClick={() => setDisplayType('$')}
              style={buttonStyle('$')}
              title="Location Total"
            >
              $
            </Button>
          </Grid>
          <Grid item sx={{ mt: 5 }}>
            <Button
              variant="contained"
              onClick={() => setDisplayType('%')}
              style={buttonStyle('%')}
              title="Percentage of Location Total"
            >
              %
            </Button>
          </Grid>
        </Grid>

        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data &&
          (reportQuery.data.metrics.length === 0 ? (
            <Typography variant="h4">
              No data available for the selected location
            </Typography>
          ) : (
            <VgtSnapshotChart
              data={reportQuery.data.metrics}
              displayType={displayType}
            />
          ))}
      </Stack>
    </ReportsHeader>
  )
}
