import { useState, useEffect } from 'react'
import { type GridColDef } from '@mui/x-data-grid'
import { useGetPatronCheckins } from 'hooks/api/useGetPatronCheckins'
import { CircularProgress } from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import { formatDateTime } from 'utils/util'
import { useGetMe } from 'hooks/api/useGetMe'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { colorPrimitives } from 'components/Theme'
import { usePatronCheckInsDateRangeState } from 'stores/usePatronCheckInsDateRange'
import { type FullPatronCheckInDTO } from 'src/types/api'

const columns: GridColDef[] = [
  {
    field: 'patronId',
    headerName: 'Patron ID',
    minWidth: 120,
    flex: 1,
    valueFormatter: ({ value }) => `#${value}`,
  },
  {
    field: 'licensedEstablishmentName',
    headerName: 'Licensed Establishment',
    minWidth: 130,
    flex: 2,
    valueGetter: (params) => params.row.licensedEstablishment?.name,
  },
  {
    field: 'licensedEstablishmentLicenseNumber',
    headerName: 'License Number',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => params.row.licensedEstablishment?.licenseNumber,
  },
  {
    field: 'patronCheckinDate',
    headerName: 'Check in Time',
    minWidth: 130,
    flex: 2,
    valueGetter: (params) => params.row.createdOn,
    valueFormatter: ({ value }) => formatDateTime(value),
  },
]
export const CheckedInPatronsTable = () => {
  const [checkIns, setCheckIns] = useState<FullPatronCheckInDTO[]>([])
  const { startDate, endDate, licensedEstablishmentId } =
    usePatronCheckInsDateRangeState()
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  const patronCheckinQuery = useGetPatronCheckins({
    corporateAccountId: selectedCorporateAccountId,
  })

  useEffect(() => {
    if (licensedEstablishmentId !== -1) {
      setCheckIns(
        patronCheckinQuery.data?.filter(
          (x) =>
            x.createdOn &&
            new Date(x.createdOn) >= startDate &&
            new Date(x.createdOn) <= endDate &&
            x.licensedEstablishmentId === licensedEstablishmentId
        ) ?? []
      )
    } else {
      setCheckIns(
        patronCheckinQuery.data?.filter(
          (x) =>
            x.createdOn &&
            new Date(x.createdOn) >= startDate &&
            new Date(x.createdOn) <= endDate
        ) ?? []
      )
    }
  }, [startDate, endDate, licensedEstablishmentId, patronCheckinQuery.data])

  if (meQuery.isPending || patronCheckinQuery.isPending) {
    return <CircularProgress />
  }

  if (meQuery.isError || patronCheckinQuery.isError) {
    return <p>An error occurred.</p>
  }

  const patronCheckins = patronCheckinQuery.data

  if (patronCheckins.length === 0) {
    return <NoDataIndicator />
  }

  return (
    <DataTable
      slotProps={{
        row: {
          style: { cursor: 'default' },
        },
      }}
      imageSource={NoPatronsCheckedInIcon}
      noDataMessage="There are no patron accounts related to this corporate account"
      columns={columns}
      rows={checkIns}
      sx={{
        color: colorPrimitives.black,
        '& .MuiDataGrid-row:hover': {
          cursor: 'auto',
        },
      }}
    />
  )
}
