import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { ReportsTabs } from './ReportsTabs'
import { useParams } from 'react-router-dom'

export const ReportsHeader = ({
  currentTab,
  children,
}: {
  currentTab: string
  children?: React.ReactNode
}) => {
  const { id } = useParams()

  return (
    <Page
      header={
        <PageHeader
          title="View Reports"
          isSecondary={true}
          tabs={<ReportsTabs currentTab={currentTab} locationId={Number(id)} />}
        />
      }
    >
      {children}
    </Page>
  )
}
