import { Box, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { ActivityButton } from 'components/ActivityButton'
import { colorPrimitives } from 'components/Theme'
import { useGetFlexPayStatementsByLeId } from 'hooks/api/Reports/useGetFlexPayStatementsByLeId'
import { formatFullDate, pdfDownloadFromByteArray } from 'utils/util'
import { useGetDownloadFlexPayStatements } from 'hooks/api/Reports/useGetDownloadFlexPayStatements'
import { useSnackbar } from 'stores/useSnackbar'
import { useParams } from 'react-router-dom'
import { ReportsHeader } from '../ReportsHeader'

export const FlexPayPage = () => {
  const { id } = useParams()
  const [activeId, setActiveId] = useState<number | null>(null)
  const [downloadedReports, setDownloadedReports] = useState<number[]>([])

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const reportQuery = useGetFlexPayStatementsByLeId({
    licensedEstablishmentId: Number(id),
    enabled: id !== undefined,
  })

  const dataRows = Array.isArray(reportQuery.data) ? reportQuery.data : []

  const useGetDownloadFlexPayStatementsMutation =
    useGetDownloadFlexPayStatements({
      onSuccess: () => {},
      onError: () => {},
    })

  const handleDownload = (params: any) => {
    setActiveId(params.id)

    useGetDownloadFlexPayStatementsMutation.mutate(
      {
        licensedEstablishmentId: params.row.licensedEstablishmentId,
        license: params.row.license,
        type: params.row.type,
        blobName: params.row.blobName,
        blobDate: params.row.date,
      },
      {
        onSuccess: (data) => {
          setDownloadedReports([...downloadedReports, params.id])
          pdfDownloadFromByteArray(data, params.row.blobName)
          setActiveId(null)
          setSnackbarMessage('Report successfully downloaded')
        },
        onError: (error) => {
          setActiveId(null)
          console.error('Error:', error)
          setSnackbarMessage(
            'There was an error downloading the Report',
            'error'
          )
        },
      }
    )
  }

  const columns = [
    {
      field: 'name',
      headerName: 'LE Name',
      flex: 0.25,
      valueGetter: (params: {
        row: { licensedEstablishment: { name: string } }
      }) => params.row.licensedEstablishment.name,
    },
    { field: 'type', headerName: 'Type', flex: 0.25 },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.25,
      valueGetter: (params: { row: { date: string } }) => params.row.date,
      valueFormatter: ({ value }: { value: string }) => formatFullDate(value),
    },
    {
      field: 'download',
      headerName: '',
      sortable: false,
      width: 175,
      renderCell: (params: { row: any }) =>
        !downloadedReports?.includes(params.row.id) ? (
          <ActivityButton
            sx={{
              border: `1px solid ${colorPrimitives.redGaming}`,
              background: 'white',
            }}
            active={
              useGetDownloadFlexPayStatementsMutation.isPending &&
              activeId === params.row.id
            }
            fullWidth
            variant="outlined"
            className="px-6 py-2 rounded"
            onClick={() => handleDownload(params)}
          >
            Download
          </ActivityButton>
        ) : (
          <ActivityButton
            active={
              useGetDownloadFlexPayStatementsMutation.isPending &&
              activeId === params.row.id
            }
            color="success"
            variant="contained"
            onClick={() => handleDownload(params)}
          >
            Complete
          </ActivityButton>
        ),
    },
  ]

  return (
    <ReportsHeader currentTab={`/Reports/${id}/FlexPay`}>
      <Stack spacing={4}>
        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {!reportQuery.data ? (
          <Typography variant="h4">
            No data available for the selected location
          </Typography>
        ) : (
          <Box py={3}>
            <Typography pb={3} variant="h3">
              Download Reports
            </Typography>
            <DataTable
              rowHeight={80}
              columns={columns}
              rows={dataRows}
              getRowId={(row) => row.id}
              hideFooterSelectedRowCount
            />
          </Box>
        )}
      </Stack>
    </ReportsHeader>
  )
}
