import ListBullet from 'assets/format_list_bulleted.svg'
import { Menu, MenuItem, IconButton } from '@mui/material'
import { type GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { type LicensedEstablishmentUser } from 'src/types/api'
import { DeleteLEConnectUsesrDialog } from './DeleteLEConnectUserDialog'
import { useDeleteLicensedEstablishmentUserFromCorporateAccount } from 'hooks/api/useDeleteLicensedEstablishmentUserFromCorporateAccount'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useSnackbar } from 'stores/useSnackbar'

export const DeleteLicensedEstablishmentUserRenderCell = ({
  params,
}: {
  params: GridRenderCellParams<LicensedEstablishmentUser>
}) => {
  const currentCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const useDeleteUserMutation =
    useDeleteLicensedEstablishmentUserFromCorporateAccount({
      onSuccess: () => {
        setSnackbarMessage(`User successfully deleted`, 'success')
        setIsModalOpen(false)
      },
      onError: () => {
        setSnackbarMessage(
          `An error has occured while deleting the user`,
          'error'
        )
        setIsModalOpen(false)
      },
    })

  const handleMenuOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const onDeleteButtonClicked = () => {
    useDeleteUserMutation.mutate({
      id: params.row.id,
      corporateAccountId: Number(currentCorporateAccountId),
    })
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <img src={ListBullet} width={4} height={16} alt="options icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose()
            handleOpenModal()
          }}
        >
          Delete User
        </MenuItem>
      </Menu>
      <DeleteLEConnectUsesrDialog
        isModalOpen={isModalOpen}
        onClose={onModalClose}
        onDelete={onDeleteButtonClicked}
        userName={`${params.row.firstName} ${params.row.lastName}`}
        userEmail={params.row.email}
        userRole={params.row.type}
      />
    </>
  )
}
