import { type UseQueryResult } from '@tanstack/react-query'
import { useApiQuery } from 'hooks/useApiQuery'
import { type LicensedEstablishmentUser } from 'src/types/api'

export const useGetLicensedEstablishmentUsersByCorporateAccount = (
  corporateAccountId?: number
): UseQueryResult<LicensedEstablishmentUser[]> => {
  return useApiQuery({
    path: '/le-connect/licensed-establishments/users/corporate-account/{corporateAccountId}',
    method: 'get',
    queryKey: [
      '/le-connect/licensed-establishments/users/corporate-account',
      corporateAccountId,
    ],
    queryArgs: {
      corporateAccountId,
    },
  })
}
