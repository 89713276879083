import { Box, Grid } from '@mui/material'
import { LicenseCardLicensedEstablishmentPage } from './LicenseCardLicensedEstablishmentPage'
import { type LicensedEstablishmentLicense } from 'src/types/api'

export const LicenseCardBodyLicensedEstablishmentPage = ({
  licensedEstablishmentLicenses,
}: {
  licensedEstablishmentLicenses: LicensedEstablishmentLicense[]
}) => {
  const orderLicenses = ['StateLiquor', 'LocalLiquor', 'Gaming']
  const sortedLicenses = licensedEstablishmentLicenses.sort((o1, o2) => {
    const index1 = orderLicenses.indexOf(
      String(o1.licensedEstablishmentLicenseType)
    )
    const index2 = orderLicenses.indexOf(
      String(o2.licensedEstablishmentLicenseType)
    )
    return index1 - index2
  })
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container rowSpacing={1} columnSpacing={2} sx={{ px: 3 }}>
        {licensedEstablishmentLicenses.length === 0 ? (
          <LicenseCardLicensedEstablishmentPage />
        ) : (
          sortedLicenses.map((license) => {
            return (
              <LicenseCardLicensedEstablishmentPage
                key={license.id}
                license={license}
              />
            )
          })
        )}
      </Grid>
    </Box>
  )
}
