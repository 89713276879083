import { useGetMe } from 'hooks/api/useGetMe'
import { useGetDashBoard } from 'hooks/api/useGetDashboard'
import { Box, Divider, Stack, Typography, Grid } from '@mui/material'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useLayout } from 'hooks/useLayout'
import { colorPrimitives } from 'components/Theme'
import { useNavigate } from 'react-router-dom'
import { usePatronCheckInsDateRangeState } from 'stores/usePatronCheckInsDateRange'

interface CheckInMetricProps {
  label: string
  metric: number
  isMobile: boolean
}

const CheckInMetric = ({ label, metric, isMobile }: CheckInMetricProps) => {
  const navigate = useNavigate()
  const { setStartDate, setEndDate } = usePatronCheckInsDateRangeState()

  return (
    <Stack
      onClick={() => {
        const now = new Date()
        const currentYear = now.getFullYear()
        const currentMonth = now.getMonth()
        const currentDay = now.getDate()
        if (label === 'Check Ins Today') {
          setStartDate(
            new Date(currentYear, currentMonth, currentDay, 0, 0, 0, 0)
          )
          setEndDate(
            new Date(currentYear, currentMonth, currentDay, 23, 59, 59, 999)
          )
        } else if (
          label === 'Check Ins this Month' ||
          label === 'VIP Check Ins this Month'
        ) {
          setStartDate(new Date(currentYear, currentMonth, 1, 0, 0, 0, 0))
          setEndDate(
            new Date(currentYear, currentMonth, currentDay, 23, 59, 59, 999)
          )
        } else if (label === 'YTD Check Ins') {
          setStartDate(new Date(currentYear, 0, 1, 0, 0, 0, 0))
          setEndDate(
            new Date(currentYear, currentMonth, currentDay, 23, 59, 59, 999)
          )
        }

        if (
          label === 'Check Ins Today' ||
          label === 'Check Ins this Month' ||
          label === 'YTD Check Ins'
        ) {
          navigate(`/PatronAccounts`)
        }
      }}
      className={
        isMobile
          ? 'bg-white rounded-lg shadow-md mx-1 p-2 pb-5 w-full'
          : `bg-white rounded-lg shadow-md mx-1 p-2 pb-5 w-1/2 md:w-full md:rounded-none md:shadow-none md:mx-0 md:p-0 md:px-6 ${
              label !== 'Total Patrons' && label !== 'VIP Check Ins this Month'
                ? `cursor-pointer hover:bg-gray-100`
                : ''
            }`
      }
    >
      <Typography variant="caption">{label}</Typography>
      <Typography variant="h2" data-testid={`Dashboard-${label}-Metric`}>
        {metric}
      </Typography>
    </Stack>
  )
}

interface CheckInMetricsProps {
  dailyCheckIns: number
  monthlyCheckIns: number
  monthlyVipCheckIns: number
  yearlyCheckIns: number
  uniquePatrons: number
}

const CheckInDivider = () => (
  <Divider orientation="vertical" flexItem className="md:visible invisible" />
)

const CheckInMetrics = ({
  dailyCheckIns,
  monthlyCheckIns,
  monthlyVipCheckIns,
  yearlyCheckIns,
  uniquePatrons,
}: CheckInMetricsProps) => {
  const isMobile = useLayout().isMobile

  return isMobile ? (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <CheckInMetric
          label="Check Ins Today"
          metric={dailyCheckIns}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckInMetric
          label="Check Ins this Month"
          metric={monthlyCheckIns}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={3}>
        <CheckInMetric
          label="VIP Check Ins this Month"
          metric={monthlyVipCheckIns}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={2}>
        <CheckInMetric
          label="YTD Check Ins"
          metric={yearlyCheckIns}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={2}>
        <CheckInMetric
          label="Total Patrons"
          metric={uniquePatrons}
          isMobile={isMobile}
        />
      </Grid>
    </Grid>
  ) : (
    <Stack
      direction="row"
      justifyContent="flex-start"
      className="w-[175vw] md:w-full md:bg-white md:rounded-lg md:shadow-md md:py-4"
    >
      <CheckInMetric
        label="Check Ins Today"
        metric={dailyCheckIns}
        isMobile={isMobile}
      />
      <CheckInDivider />
      <CheckInMetric
        label="Check Ins this Month"
        metric={monthlyCheckIns}
        isMobile={isMobile}
      />
      <CheckInDivider />
      <CheckInMetric
        label="VIP Check Ins this Month"
        metric={monthlyVipCheckIns}
        isMobile={isMobile}
      />
      <CheckInDivider />
      <CheckInMetric
        label="YTD Check Ins"
        metric={yearlyCheckIns}
        isMobile={isMobile}
      />
      <CheckInDivider />
      <CheckInMetric
        label="Total Patrons"
        metric={uniquePatrons}
        isMobile={isMobile}
      />
    </Stack>
  )
}

interface DashBoardHeaderCorporateAccountMetricsProps {
  corporateAccountId: number | undefined
  corporateAccountName: string | undefined
}

const DashBoardHeaderCorporateAccountMetrics = ({
  corporateAccountId,
  corporateAccountName,
}: DashBoardHeaderCorporateAccountMetricsProps) => {
  const dashboardQuery = useGetDashBoard({
    corporateAccountId,
  })
  const { isMobile } = useLayout()

  if (corporateAccountId === undefined) {
    return <></>
  }

  if (dashboardQuery.isPending) {
    return <ActivityIndicator />
  }

  if (dashboardQuery.isError) {
    return <ErrorIndicator />
  }

  return (
    <>
      <Box className="mb-3">
        <Typography variant="body-3" color={colorPrimitives.darkGray}>
          Viewing stats for {corporateAccountName}
        </Typography>
      </Box>

      <Box className="relative">
        {!isMobile && (
          <Box className="absolute -left-4 px-3 top-0 w-screen pb-2 overflow-x-auto md:overflow-visible md:left-0 md:px-0 md:w-full">
            <CheckInMetrics
              dailyCheckIns={dashboardQuery.data.numCheckinsForToday}
              monthlyCheckIns={dashboardQuery.data.numCheckinsForThisMonth}
              monthlyVipCheckIns={
                dashboardQuery.data.numVipCheckinsForThisMonth
              }
              yearlyCheckIns={dashboardQuery.data.numCheckinsForThisYear}
              uniquePatrons={dashboardQuery.data.numUniquePatrons}
            />
          </Box>
        )}
        {isMobile && (
          <CheckInMetrics
            dailyCheckIns={dashboardQuery.data.numCheckinsForToday}
            monthlyCheckIns={dashboardQuery.data.numCheckinsForThisMonth}
            monthlyVipCheckIns={dashboardQuery.data.numVipCheckinsForThisMonth}
            yearlyCheckIns={dashboardQuery.data.numCheckinsForThisYear}
            uniquePatrons={dashboardQuery.data.numUniquePatrons}
          />
        )}
      </Box>
    </>
  )
}

interface DashBoardHeaderProps {
  corporateAccountId: number | undefined
  corporateAccountName: string | undefined
}

export const DashBoardHeader = ({
  corporateAccountId,
  corporateAccountName,
}: DashBoardHeaderProps) => {
  const meQuery = useGetMe()
  return (
    <Stack className="px-4 pt-14 pb-24 bg-[#f7f7f6] md:px-10 ">
      <Typography variant="h2">
        Welcome Back, {meQuery.data?.user?.firstName ?? 'Unknown'}!
      </Typography>

      <Stack className="pt-8">
        <DashBoardHeaderCorporateAccountMetrics
          corporateAccountId={corporateAccountId}
          corporateAccountName={corporateAccountName}
        />
      </Stack>
    </Stack>
  )
}
