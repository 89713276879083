import type * as React from 'react'
import {
  NAVIGATION_BAR_WIDTH,
  NavigationBar,
} from 'components/NavigationBar/NavigationBar'
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Snackbar,
  Stack,
} from '@mui/material'
import { APP_BAR_HEIGHT, MOBILE_APP_BAR_HEIGHT, AppBar } from './AppBar/AppBar'
import { useSnackbar } from 'stores/useSnackbar'
import { isPresent } from '@jjvgaming/player-payback-library'
import { Footer, footerMaxHeight } from './Footer/Footer'

export const Page = (props: {
  children: React.ReactNode
  header?: React.ReactNode
  childrenClassName?: string
  fitBody?: boolean
  footer?: React.ReactNode
  grayBackground?: boolean
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const snackbarMessage = useSnackbar((state) => state.message)
  const snackbarSeverity = useSnackbar((state) => state.severity)
  const snackbarVisible = useSnackbar((state) => state.visible)

  const fitBodyPX = () => {
    if (
      !isPresent(props.fitBody) ||
      (isPresent(props.fitBody) && !props.fitBody)
    ) {
      return { xs: '16px', sm: '41px' }
    }
    return {}
  }

  const fitBodyPY = () => {
    if (
      !isPresent(props.fitBody) ||
      (isPresent(props.fitBody) && !props.fitBody)
    ) {
      return { xs: '21px', sm: '32px' }
    }
    return {}
  }

  return (
    <>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <AppBar />
      </Container>
      <Stack
        direction="row"
        pb={{
          xs: `${props.footer ? footerMaxHeight : 0 + 21}px`,
          sm: `${props.footer ? footerMaxHeight : 0 + 32}px`,
        }}
      >
        <NavigationBar />
        <Box
          overflow={'visible'}
          flex="1"
          position="relative"
          top={{
            xs: `${MOBILE_APP_BAR_HEIGHT}px`,
            sm: `${MOBILE_APP_BAR_HEIGHT}px`,
            md: `${APP_BAR_HEIGHT}px`,
            lg: `${APP_BAR_HEIGHT}px`,
          }}
          ml={{
            xs: 0,
            sm: 0,
            md: 0,
            lg: `${NAVIGATION_BAR_WIDTH}px`,
          }}
        >
          {props.header}
          <Box
            className={props.childrenClassName}
            sx={{
              backgroundColor: props.grayBackground ? '#f7f7f7' : 'inherit',
            }}
            px={fitBodyPX}
            py={fitBodyPY}
          >
            {props.children}
          </Box>
        </Box>
        <Snackbar
          open={snackbarVisible}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarMessage(null)
          }}
          message={snackbarMessage}
        >
          <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
        </Snackbar>
        {props.footer && (
          <Footer navigationBarWidth={NAVIGATION_BAR_WIDTH}>
            {props.footer}
          </Footer>
        )}
      </Stack>
    </>
  )
}
