import { random } from 'lodash'
import { useGetRewardRedemptionByRewardProgram } from 'hooks/api/Rewards/useGetRewardRedemptionByRewardProgram'
import { DataTable } from 'components/Shared/DataTable'
import { RedemptionColumns } from './RedemptionColumns'
import { useGetMe } from 'hooks/api/useGetMe'

export const RedemptionSection = ({
  rewardProgramId,
  daysDisplayed,
}: {
  rewardProgramId: number
  daysDisplayed: string
}) => {
  const redemptionQuery = useGetRewardRedemptionByRewardProgram({
    rewardProgramId,
    startTransactionDate: new Date(
      new Date().getTime() - Number(daysDisplayed) * 24 * 60 * 60 * 1000
    ).toLocaleString(),
    endTransactionDate: new Date().toLocaleString(),
  })

  if (redemptionQuery.isError) {
    return <p>An error occurred.</p>
  }

  let RedemptionColumnsModified = RedemptionColumns
  const meQuery = useGetMe()
  const showPatronName = meQuery.data?.user?.reportsAccessToPIIPermission
  if (!showPatronName) {
    RedemptionColumnsModified = RedemptionColumns.slice(1)
  }

  return (
    <DataTable
      sortFieldName="patronId"
      sort="asc"
      columns={RedemptionColumnsModified}
      rows={
        redemptionQuery.data?.map((redemption) => {
          let displayPatronName = ''
          if (showPatronName) {
            displayPatronName =
              redemption.patron?.firstName + ' ' + redemption.patron?.lastName
          }
          return {
            patronId: redemption.patronId,
            patronName: displayPatronName,
            rewardItemId: redemption.rewardsCatalogReward?.rewardId,
            rewardItemName: redemption.rewardsCatalogReward?.reward?.name,
            licensedEstablishmentName:
              redemption.rewardsProgram?.licensedEstablishment?.name,
            pointCost: redemption.rewardsCatalogReward?.amount,
            timeOfredemption: redemption.createdOn,
            rewardProgramName: redemption.rewardsProgram?.name,
          }
        }) ?? []
      }
      getRowId={() => random(0, 1000, true)}
      style={{ maxWidth: 1580 }}
    ></DataTable>
  )
}
