import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeleteLicensedEstablishmentUserFromCorporateAccountParams {
  id: number
  corporateAccountId: number
}

interface useDeleteLicensedEstablishmentUserFromCorporateAccountProps {
  onSuccess?: (
    data: undefined,
    variables: useDeleteLicensedEstablishmentUserFromCorporateAccountParams
  ) => void
  onError?: (
    error: Error,
    variables: useDeleteLicensedEstablishmentUserFromCorporateAccountParams
  ) => void
}

export const useDeleteLicensedEstablishmentUserFromCorporateAccount = ({
  onSuccess,
  onError,
}: useDeleteLicensedEstablishmentUserFromCorporateAccountProps) => {
  const deleteUser = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/{id}/corporate-account/{corporateAccountId}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<
    any,
    Error,
    useDeleteLicensedEstablishmentUserFromCorporateAccountParams
  >({
    mutationFn: async ({
      id,
      corporateAccountId,
    }: useDeleteLicensedEstablishmentUserFromCorporateAccountParams) => {
      await deleteUser({
        id: Number(id),
        corporateAccountId: Number(corporateAccountId),
      })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [
          '/le-connect/licensed-establishments/users/corporate-account',
        ],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
