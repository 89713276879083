import { Tab, Tabs } from '@mui/material'
import { useGetMe } from 'hooks/api/useGetMe'
import { Link } from 'react-router-dom'
import { SelectLicensedEstablishment } from './SelectLicensedEstablishment'
import { useState } from 'react'
import { type LicensedEstablishment } from 'src/types/api'

export const ReportsTabs = ({
  currentTab,
  locationId,
}: {
  currentTab: any
  locationId: number
}) => {
  const meQuery = useGetMe()
  const financialPermission = meQuery.data?.user?.reportsFinancialPermission
  const nonFinancialPermission =
    meQuery.data?.user?.reportsNonFinancialPermission

  const [licensedEstablishment, setLicensedEstablishment] = useState<
    LicensedEstablishment | undefined
  >()

  const leId = licensedEstablishment?.id ?? locationId
  const selectedLocationId = isNaN(leId) ? '' : leId

  const nonFinancialTabs = [
    {
      label: 'Patron Activity',
      value: `/Reports/${leId}/PatronActivity`,
      to: `/Reports/${leId}/PatronActivity`,
    },
  ]

  const financialTabs = [
    {
      label: 'Credits Wagered by Hour',
      value: `/Reports/${leId}/CreditsWageredByHour`,
      to: `/Reports/${leId}/CreditsWageredByHour`,
    },
    {
      label: 'Credits Wagered by Machine',
      value: `/Reports/${leId}/CreditsWageredByMachine`,
      to: `/Reports/${leId}/CreditsWageredByMachine`,
    },
    {
      label: 'Credits Wagered: Hourly Trends',
      value: `/Reports/${leId}/CreditsWageredHourlyTrends`,
      to: `/Reports/${leId}/CreditsWageredHourlyTrends`,
    },
    {
      label: 'FlexPay / Income Statements',
      value: `/Reports/${leId}/FlexPay`,
      to: `/Reports/${leId}/FlexPay`,
    },
    {
      label: 'NTI Trends',
      value: `/Reports/${leId}/TrailingNtiTrends`,
      to: `/Reports/${leId}/TrailingNtiTrends`,
    },
    {
      label: 'Patron Check-ins',
      value: `/Reports/${leId}/PatronCheckins`,
      to: `/Reports/${leId}/PatronCheckins`,
    },
    {
      label: 'Patron List',
      value: `/Reports/${leId}/PatronList`,
      to: `/Reports/${leId}/PatronList`,
    },
    {
      label: 'Patron Reward Redemptions',
      value: `/Reports/${leId}/PatronRewardRedemptions`,
      to: `/Reports/${leId}/PatronRewardRedemptions`,
    },
    {
      label: 'VGT Snapshot',
      value: `/Reports/${leId}/VgtSnapshot`,
      to: `/Reports/${leId}/VgtSnapshot`,
    },
    {
      label: 'VGT Trends',
      value: `/Reports/${leId}/VgtTrends`,
      to: `/Reports/${leId}/VgtTrends`,
    },
    {
      label: 'Weekly Snapshot',
      value: `/Reports/WeeklySnapshotLocationSelect`,
      to: `/Reports/WeeklySnapshotLocationSelect`,
    },
  ]

  return (
    <div>
      <SelectLicensedEstablishment
        onSelectLicensedEstablishment={setLicensedEstablishment}
        locationId={selectedLocationId}
      />
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label="Overview"
          value="/Reports/ReportsOverview"
          to="/Reports"
          component={Link}
        />
        {nonFinancialPermission &&
          nonFinancialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={
                isNaN(locationId) && licensedEstablishment === undefined
              }
            />
          ))}
        {financialPermission &&
          financialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={
                isNaN(locationId) && licensedEstablishment === undefined
              }
            />
          ))}
      </Tabs>
    </div>
  )
}
