import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const DeleteLEConnectUsesrDialog = ({
  isModalOpen,
  onClose,
  onDelete,
  userName,
  userEmail,
  userRole,
}: {
  isModalOpen: boolean
  onClose: () => void
  onDelete: () => void
  userName: string
  userEmail: string
  userRole: string
}) => {
  const currentCorporateAccountName =
    useCurrentCorporateAccountStore().currentCorporateAccountName
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        onClose()
      }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={{ xs: 2, sm: 4 }} py={{ xs: 3, sm: 4 }}>
          <Typography variant="h3">Delete J&J Connect User</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="pt-4">
          <Typography className="text-gaming-red">
            Deleting this user will remove them from{' '}
            {currentCorporateAccountName} and all associated accounts.
          </Typography>
        </Box>
        <Box paddingBottom={2} paddingTop={3}>
          <Typography variant="h3" fontWeight={'bold'}>
            {userName}
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {userEmail}
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {userRole}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className="border-t">
        <Box className="flex flex-row justify-end py-4 md:px-8 sm:px-4">
          <div className="px-2">
            <Button
              variant="text"
              onClick={async () => {
                onClose()
              }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={async () => {
              onDelete()
            }}
          >
            Delete {userName}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
