import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import * as _ from 'lodash'
import type { Promotion } from 'src/types/api'

const path = '/le-connect/promotions/{id}'
const method = 'get' as const

export const useGetPromotionById = (
  promotionId: number | undefined
): UseQueryResult<Promotion> => {
  const get = useTypedApiClient({ path, method })
  return useQuery({
    queryKey: ['/admin/promotions', promotionId],
    queryFn: async () => {
      if (_.isNil(promotionId)) {
        throw new Error('promotionId is required')
      }
      const { data } = await get({ id: promotionId })
      return data
    },
    enabled: !!promotionId,
  })
}
