import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { isNil, startsWith } from 'lodash'
import { NAVIGATION_BAR_WIDTH } from './NavigationBar'
import { useNavBar } from 'hooks/useNavBar'
import { grey } from '@mui/material/colors'
import ReportsIcon from 'assets/ReportsIcon.svg'
import ReportsIconRed from 'assets/ReportsIconRed.svg'
import HomeIcon from 'assets/HomeIcon.svg'
import HomeIconRed from 'assets/HomeIconRed.svg'
import PromotionsIcon from 'assets/PromotionsIcon.svg'
import PromotionsIconRed from 'assets/PromotionsIconRed.svg'
import RewardsIcon from 'assets/RewardsIcon.svg'
import RewardsIconRed from 'assets/RewardsIconRed.svg'
import LocationsIcon from 'assets/LocationsIcon.svg'
import LocationsIconRed from 'assets/LocationsIconRed.svg'
import PatronIcon from 'assets/PatronIcon.svg'
import PatronIconRed from 'assets/PatronIconRed.svg'
import CommunicationsIcon from 'assets/CommunicationsIcon.svg'
import CommunicationsIconRed from 'assets/CommunicationsIconRed.svg'
import SweepstakeIcon from 'assets/SweepstakeIcon.svg'
import SweepstakeIconRed from 'assets/SweepstakeIconRed.svg'
// import LiveLocationIcon from 'assets/LiveLocationIcon.svg'
// import LiveLocationIconRed from 'assets/LiveLocationIconRed.svg'
import { useGetMe } from 'hooks/api/useGetMe'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { type LicensedEstablishmentUser } from 'src/types/api'
import { type Path } from 'src/navigation/paths'
import { isPathAvailable } from 'src/navigation/pathPermissions'
import { useLayout } from 'hooks/useLayout'

const navRoutes: Path[] = [
  '/Home',
  '/Reports',
  // '/LiveAtLocation',
  '/PatronAccounts',
  '/Locations',
  '/Rewards',
  '/Promotions',
  '/Sweepstakes',
  '/LicensedEstablishmentUsers',
]

interface RouteProps {
  title: string
  defaultIcon: string
  selectedIcon: string
}

const routeProps: Partial<Record<Path, RouteProps>> = {
  '/Home': {
    title: 'Dashboard',
    defaultIcon: HomeIcon,
    selectedIcon: HomeIconRed,
  },
  '/LicensedEstablishmentUsers': {
    title: 'J&J Connect Users',
    defaultIcon: CommunicationsIcon,
    selectedIcon: CommunicationsIconRed,
  },
  // '/LiveAtLocation': {
  //   title: 'Live at Location',
  //   defaultIcon: LiveLocationIcon,
  //   selectedIcon: LiveLocationIconRed,
  // },
  '/Locations': {
    title: 'Locations',
    defaultIcon: LocationsIcon,
    selectedIcon: LocationsIconRed,
  },
  '/PatronAccounts': {
    title: 'Patron Check Ins',
    defaultIcon: PatronIcon,
    selectedIcon: PatronIconRed,
  },
  '/Promotions': {
    title: 'Specials & Events',
    defaultIcon: PromotionsIcon,
    selectedIcon: PromotionsIconRed,
  },
  '/Rewards': {
    title: 'Rewards',
    defaultIcon: RewardsIcon,
    selectedIcon: RewardsIconRed,
  },
  '/Reports': {
    title: 'Reports',
    defaultIcon: ReportsIcon,
    selectedIcon: ReportsIconRed,
  },
  '/Sweepstakes': {
    title: 'Sweepstakes',
    defaultIcon: SweepstakeIcon,
    selectedIcon: SweepstakeIconRed,
  },
}

export const Content = () => {
  const { isMobile } = useLayout()
  const location = useLocation()
  const navigate = useNavigate()
  const close = useNavBar((state) => state.close)

  const leUserQuery = useGetMe()

  if (leUserQuery.isPending) {
    return <ActivityIndicator />
  }

  const user: LicensedEstablishmentUser | undefined = leUserQuery?.data?.user
  if (isNil(user)) {
    throw new Error('J&J Connect user is undefined')
  }
  const availableNavRoutes = navRoutes.filter((r) => isPathAvailable(r, user))

  return (
    <List
      sx={{
        ml: isMobile ? -0.8 : 0,
        backgroundColor: 'white',
        flex: 1,
        width: isMobile
          ? `${NAVIGATION_BAR_WIDTH * 1.37}px`
          : `${NAVIGATION_BAR_WIDTH}px`,
        borderRightStyle: 'solid',
        borderRightColor: grey[300],
        borderRightWidth: 1,
      }}
    >
      {availableNavRoutes.map((route) => {
        const routeInfo = routeProps[route]
        const isSelected = startsWith(location.pathname, route)
        return (
          <ListItem sx={{ px: 1 }} key={route}>
            <ListItemButton
              onClick={() => {
                navigate(route)
                close()
              }}
              selected={isSelected}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  transform: 'translateY(-2px)',
                }}
              >
                <img
                  src={
                    isSelected
                      ? routeInfo?.selectedIcon
                      : routeInfo?.defaultIcon
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={routeInfo?.title}
                primaryTypographyProps={{
                  fontWeight: isSelected ? 'bold' : 'normal',
                }}
              />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}
