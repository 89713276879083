import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { useGetPromotionById } from 'hooks/api/useGetPromotionById'
import { PromotionsDetailsHeader } from './PromotionDetailsHeader'
import { Page } from 'components/Page'
import { useLayout } from 'hooks/useLayout'
import { PromotionDetailsDesktop } from './PromotionDetailsDesktop'
import { PromotionDetailsMobile } from './PromotionDetailsMobile'
import { isIdPresent } from 'utils/typeUtils'
import { SectionHeader } from 'components/SectionHeader'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'

export const PromotionDetailsPage = () => {
  const { isMobile } = useLayout()
  const promotionId = Number(useParams().id)
  const getPromotionQuery = useGetPromotionById(promotionId)

  if (getPromotionQuery.isPending) {
    return <CircularProgress />
  }

  if (getPromotionQuery.isError) {
    return <p>An error occurred.</p>
  }

  const promotion = getPromotionQuery.data

  if (isNil(promotion) || !isIdPresent(promotion)) {
    return <p>An error occurred.</p>
  }

  return (
    <Page header={<PromotionsDetailsHeader promotion={promotion} />}>
      {!isMobile && <PromotionDetailsDesktop promotion={promotion} />}
      {isMobile && <PromotionDetailsMobile promotion={promotion} />}

      {/* Image section */}
      <SectionHeader title="Image" showButton={false} sx={{ marginTop: 7 }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          px: '16px',
          py: '16px',
          mt: '12px',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} sx={{ pt: { xs: '24px', sm: 0 } }}>
            <Typography variant="body-3" className="w-full">
              Thumbnail Image
            </Typography>
            <SimpleImageCard
              imageSource={promotion.publicImageUrl}
              sx={{ maxWidth: '358px', maxHeight: '358px' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}
