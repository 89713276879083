import { LicensedEstablishmentDetailsDesktop } from 'components/LicensedEstablishmentDetails/LicensedEstablishmentDetailsDesktop'
import { Box, CircularProgress, Divider } from '@mui/material'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useParams } from 'react-router-dom'
import { getLastUpdated } from 'utils/util'
import { isNil } from 'lodash'
import { useGetLicensedEstablishmentById } from 'hooks/api/useGetLicensedEstablishmentById'
import { useLayout } from 'hooks/useLayout'
import { LicensedEstablishmentDetailsMobile } from 'components/LicensedEstablishmentDetails/LicensedEstablishmentDetailsMobile'

export const LicensedEstablishmentDetailsPage = () => {
  const { id } = useParams()
  const { isMobile } = useLayout()

  const licensedEstablishmentById = useGetLicensedEstablishmentById(Number(id))

  if (licensedEstablishmentById.isPending) {
    return <CircularProgress />
  }

  if (
    licensedEstablishmentById.isError ||
    isNil(licensedEstablishmentById.data)
  ) {
    return <p>An error occurred.</p>
  }

  const licensedEstablishment = licensedEstablishmentById.data

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title={licensedEstablishment.name ?? 'Licensed Establishment'}
            backText="Locations"
            backPath="/Locations"
            isSecondary={true}
            subtitle={getLastUpdated(licensedEstablishment.modifiedOn)}
          />
          <Divider />
        </Box>
      }
    >
      {!isMobile && (
        <LicensedEstablishmentDetailsDesktop
          licensedEstablishment={licensedEstablishment}
        />
      )}
      {isMobile && (
        <LicensedEstablishmentDetailsMobile
          licensedEstablishment={licensedEstablishment}
        />
      )}
    </Page>
  )
}
