import { Tab } from '@mui/material'
import { HeaderStyledTabs } from 'components/Shared/Tabs/HeaderStyledTabs'
import { Link } from 'react-router-dom'

export const RewardProgramDetailTabs = ({
  rewardProgramId,
  currentTab,
}: {
  rewardProgramId: number
  currentTab: string
}) => {
  return (
    <HeaderStyledTabs currentTab={currentTab}>
      <Tab
        label="Overview"
        value={`/RewardsProgram/${rewardProgramId}`}
        to={`/RewardsProgram/${rewardProgramId}`}
        component={Link}
      />
      <Tab
        label="Activity Log"
        value={`/RewardsProgram/${rewardProgramId}/ActivityLog`}
        to={`/RewardsProgram/${rewardProgramId}/ActivityLog`}
        component={Link}
      />
      <Tab
        label="Redemption"
        value={`/RewardsProgram/${rewardProgramId}/Redemption`}
        to={`/RewardsProgram/${rewardProgramId}/Redemption`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
    </HeaderStyledTabs>
  )
}
