import { type UseQueryResult } from '@tanstack/react-query'
import { useApiQuery } from 'hooks/useApiQuery'
import type { Promotion } from 'src/types/api'

export const useGetPromotionsByCorporateAccountId = ({
  corporateAccountId,
}: {
  corporateAccountId: number
}): UseQueryResult<Promotion[]> => {
  return useApiQuery({
    path: '/le-connect/promotions/corporate-account/{corporateAccountId}',
    method: 'get',
    queryKey: ['/promotions/corporate-account/', corporateAccountId],
    queryArgs: { corporateAccountId },
  })
}
